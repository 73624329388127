import { createClient } from 'services/prismic'

import PrismicPage from 'components/PrismicPage'
import Spacer from 'components/Spacer'

export default function Home({ document }) {
  return (
    <>
      <Spacer y="insetY" />
      <PrismicPage document={document} />
      <Spacer y="insetY" />
    </>
  )
}

export async function getServerSideProps({ previewData }) {
  const prismic = createClient({ previewData })
  const document = await prismic.getPage('home')

  return {
    props: {
      document,
    },
  }
}
